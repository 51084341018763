<script setup>
import { Link } from '@inertiajs/vue3'
// import { debounce } from 'lodash'
import debounce from 'lodash/debounce'
import { uuid, openDialogById } from '@/helpers'
// import axios from 'axios'

// import { usePage } from '@inertiajs/vue3'

// const page = usePage()

const props = defineProps({
	isInline: {
		type: Boolean,
		default: false,
	},
	cancelText: {
		type: String,
		default: 'Cancel',
	},
	restaurantProperties: {
		type: Object,
		default: () => {},
	},
})

const emit = defineEmits(['cancel'])

const wizardStore = useWizardStore()
const appStore = useAppStore()

const groupedRestaurantProperties = wizardStore.groupedRestaurantProperties
const foodProperties = groupedRestaurantProperties.kitchen.concat(groupedRestaurantProperties.meal).concat(groupedRestaurantProperties.diet)
const otherProperties = groupedRestaurantProperties.features.concat(groupedRestaurantProperties.reservation).concat(groupedRestaurantProperties.Atmosphere)
const valuationProperties = groupedRestaurantProperties.valuation
const partnershipProperties = groupedRestaurantProperties.partnership

const selectOrDeselectLocation = (item, selected) => {
	selected ? delete wizardStore.locations[item.id] : (wizardStore.locations[item.id] = item)
	if (item.type === 9) {
		selected ? wizardStore.pickedOrder.remove(`r[]=${item.id}`) : wizardStore.pickedOrder.push(`r[]=${item.id}`)
	} else {
		selected ? wizardStore.pickedOrder.remove(`l[]=${item.id}`) : wizardStore.pickedOrder.push(`l[]=${item.id}`)
	}
}

const selectOrDeselectProp = (property, id, selected) => {
	// console.log(property, id, selected)
	if (selected) {
		switch (property) {
			case 'food':
				wizardStore.foodProperties.remove(id)
				wizardStore.pickedOrder.remove(`f[]=${id}`)
				break
			case 'other':
				wizardStore.howProperties.remove(id)
				wizardStore.pickedOrder.remove(`p[]=${id}`)
				break
			case 'valuation':
				wizardStore.valuationProperties.remove(id)
				wizardStore.pickedOrder.remove(`c[]=${id}`)
				break
			case 'partnership':
				wizardStore.partnershipProperties.remove(id)
				wizardStore.pickedOrder.remove(`ps[]=${id}`)
				break
		}
	} else if (!selected) {
		switch (property) {
			case 'food':
				wizardStore.foodProperties.push(id)
				wizardStore.pickedOrder.push(`f[]=${id}`)
				break
			case 'other':
				wizardStore.howProperties.push(id)
				wizardStore.pickedOrder.push(`p[]=${id}`)
				break
			case 'valuation':
				wizardStore.valuationProperties.push(id)
				wizardStore.pickedOrder.push(`c[]=${id}`)
				break
			case 'partnership':
				wizardStore.partnershipProperties.push(id)
				wizardStore.pickedOrder.push(`ps[]=${id}`)
				break
		}
	}
	// selected ? wizardStore[property === 'food' ? 'foodProperties' : 'howProperties'].remove(id) : wizardStore[property === 'food' ? 'foodProperties' : 'howProperties'].push(id)
}

const wizardResultsLink = computed(() => {
	return wizardStore.query
})

const locationData = computed(() => wizardStore.locationSuggestions)

// const numberOfResults = computed(() => wizardStore.resultCount)

const focus = ref(0)

watch(
	() => focus.value,
	(newVal, oldVal) => {
		const wrapper = document.getElementById('location-data-wrapper')

		if (!wrapper) return

		const top = document.getElementById(`suggestion-${focus.value}`).offsetTop
		wrapper.scrollTop = top

		// console.log(wrapper)
		// document.getElementById(`suggestion-${focus.value}`).scrollIntoView({
		// 	behavior: 'smooth',
		// })
	}
)

const fetchLocations = debounce(wizardStore.fetchLocations, 350)

function openDialog() {
	if (!appStore.isMobile) return

	openDialogById('search-wizard-dialog')
}

function arrowUp(e) {
	e.preventDefault()

	if (focus.value) focus.value--
}

function arrowDown(e) {
	e.preventDefault()

	if (focus.value < locationData.value.length - 1) focus.value++
}

const handleEnter = () => {
	if (locationData.value.length > 0) {
		// selectOrDeselectLocation(locationData.value[0])
		selectOrDeselectLocation(locationData.value[focus.value])
		wizardStore.search = ''
	}
}

function clearSuggestions() {
	setTimeout(() => {
		wizardStore.locationSuggestions = []
	}, 1000)
}
</script>

<template>
	<div
		v-if="appStore.isMobile && isInline"
		class="wizard wizard-mobile-inline bg-white"
	>
		<h5>
			{{ $t('Where do you want to eat?') }}
		</h5>

		<div class="input-wrapper">
			<div
				class="trigger"
				@click="openDialog()"
			></div>
			<input
				type="text"
				class="form-control icon-left icon-search bg-white mb-3"
				:placeholder="$t('Search for location or restaurant')"
				disabled
			/>
		</div>

		<Link
			class="btn btn-theme-500 w-100 fw-bold"
			:href="wizardResultsLink"
		>
			{{ !wizardStore.resultCount ? $t('No match, try again') : $t('Show :number restaurants', { number: '' }) }}
		</Link>
	</div>

	<div
		v-else
		class="wizard bg-white"
		:class="{
			'wizard-inline': isInline,
		}"
		:style="{
			maxWidth: appStore.isDesktop && isInline ? '535px' : null,
		}"
		@keydown.escape="wizardStore.search = ''"
	>
		<ol class="wizard-buttons">
			<li
				@click="
					() => {
						wizardStore.activeTab = 0
						wizardStore.visitedTabs.push(0)
					}
				"
			>
				<button
					class="btn btn-sm btn-white border-0"
					:class="{
						'text-active': wizardStore.activeTab === 0 && wizardStore.visitedTabs.includes(0),
					}"
					style="--lek-btn-hover-bg: var(--lek-neutral-100); color: inherit"
				>
					<i
						:class="{
							'bi-check-circle-fill text-success': wizardStore.visitedTabs.includes(0) && wizardStore.activeTab !== 0,
							'bi-1-circle': wizardStore.activeTab === 0 || !wizardStore.visitedTabs.includes(0),
						}"
					/>
					{{ $t('Where') }}
				</button>
			</li>
			<li><hr /></li>
			<li
				@click="
					() => {
						wizardStore.activeTab = 1
						wizardStore.visitedTabs.push(0)
						wizardStore.visitedTabs.push(1)
					}
				"
			>
				<button
					class="btn btn-sm btn-white border-0"
					:class="{
						'text-active': wizardStore.activeTab === 1,
					}"
					style="--lek-btn-hover-bg: var(--lek-neutral-100); color: inherit"
				>
					<i
						:class="{
							'bi-check-circle-fill text-success': wizardStore.visitedTabs.includes(1) && wizardStore.activeTab !== 1,
							'bi-2-circle': wizardStore.activeTab === 1,
						}"
					/>
					{{ $t('What') }}
				</button>
			</li>
			<li><hr /></li>
			<li
				@click="
					() => {
						wizardStore.activeTab = 2
						wizardStore.visitedTabs.push(0)
						wizardStore.visitedTabs.push(1)
						wizardStore.visitedTabs.push(2)
					}
				"
			>
				<button
					class="btn btn-sm btn-white border-0"
					:class="{
						'text-active': wizardStore.activeTab === 2,
					}"
					style="--lek-btn-hover-bg: var(--lek-neutral-100); color: inherit"
				>
					<i
						:class="{
							'bi-check-circle-fill text-success': wizardStore.visitedTabs.includes(2) && wizardStore.activeTab !== 2,
							'bi-3-circle': wizardStore.activeTab === 2,
						}"
					/>
					{{ $t('With') }}
				</button>
			</li>
		</ol>

		<h5 class="d-flex justify-content-between align-items-center mb-3">
			<span
				v-show="wizardStore.activeTab === 0"
				:key="uuid('tab-')"
				>{{ $t('Where do you want to eat?') }}</span
			>
			<span
				v-show="wizardStore.activeTab === 1"
				:key="uuid('tab-')"
				>{{ $t('What do you want to eat?') }}</span
			>
			<span
				v-show="wizardStore.activeTab === 2"
				:key="uuid('tab-')"
				>{{ $t('How do you want to eat?') }}</span
			>
			<button
				v-if="wizardStore.totalFiltersCount"
				class="btn btn-link p-0"
				@click="wizardStore.$reset()"
			>
				{{ cancelText }}
			</button>
		</h5>

		<div v-show="wizardStore.activeTab === 0">
			<div class="position-relative mb-3">
				<input
					v-model="wizardStore.search"
					type="text"
					class="form-control icon-left icon-search bg-white main-input"
					:placeholder="$t('Search by location or restaurant')"
					@keydown.arrow-up="e => arrowUp(e)"
					@keydown.arrow-down="e => arrowDown(e)"
					@input="
						e => {
							focus = 0
							fetchLocations(e.target.value)
						}
					"
					@keyup.enter="handleEnter()"
					@focus="wizardStore.visitedTabs.push(0)"
					@blur="
						() => {
							focus = 0
							wizardStore.search = ''

							clearSuggestions()
							//debounce(() => {
							//}, 2000)
						}
					"
				/>
				<ul
					id="location-data-wrapper"
					class="auto-list shadow"
					:class="{
						show: wizardStore.search,
					}"
					data-scroll-lock-scrollable
				>
					<li
						v-for="(item, itemIndex) in locationData"
						:id="`suggestion-${itemIndex}`"
						:key="`autocomplete-${item.id}-${item.type}`"
						class="auto-list-item cursor-pointer"
						:class="{
							'bg-neutral-200': focus === itemIndex,
						}"
					>
						<div
							class="px-3 py-2"
							@click="
								() => {
									selectOrDeselectLocation(item)
									wizardStore.search = ''
								}
							"
						>
							<i
								:class="[item.type === 1 ? 'str-area' : item.type === 2 ? 'str-city' : item.type === 9 ? 'str-cuisine' : '']"
								class="me-2"
							/>
							{{ item.name }}
						</div>
					</li>
				</ul>
			</div>

			<!-- <a
					class="btn btn-outline-neutral-100 btn-rounded py-2 text-body border-neutral-900"
					role="button"
					><i class="str-area" /> Prefix area</a
				> -->
		</div>

		<div v-show="wizardStore.activeTab === 1">
			<ul
				class="list-unstyled d-flex flex-wrap gap-2 mb-0 pb-3"
				:style="{
					maxHeight: '200px',
					overflowY: 'scroll',
				}"
				data-scroll-lock-scrollable
			>
				<li
					v-for="prop in foodProperties"
					:key="`prop-food-${prop.id}`"
				>
					<theme-select-badge
						:checked="wizardStore.foodProperties.includes(prop.id)"
						style="--lek-badge-font-size: 1rem; --lek-badge-font-weight: 500"
						@change:input="selectOrDeselectProp('food', prop.id, wizardStore.foodProperties.includes(prop.id))"
					>
						<i class="str-cuisine" /> {{ $t(`property.${prop.value}`) }}
					</theme-select-badge>
				</li>
			</ul>
		</div>

		<div v-show="wizardStore.activeTab === 2">
			<ul
				class="list-unstyled d-flex flex-wrap gap-2 mb-0 pb-3"
				:style="{
					maxHeight: '200px',
					overflowY: 'scroll',
				}"
				data-scroll-lock-scrollable
			>
				<li
					v-for="prop in otherProperties"
					:key="`prop-other-${prop.id}`"
				>
					<theme-select-badge
						:checked="wizardStore.howProperties.includes(prop.id)"
						style="--lek-badge-font-size: 1rem; --lek-badge-font-weight: 500"
						@change:input="selectOrDeselectProp('other', prop.id, wizardStore.howProperties.includes(prop.id))"
					>
						<i
							v-if="prop.icon"
							:class="[prop.icon]"
						/>
						{{ $t(`property.${prop.value}`) }}
					</theme-select-badge>
				</li>
			</ul>
		</div>

		<div
			class="bg-white p-3 px-md-4 pb-0 border-top mx-n3 mx-md-n4"
			style="position: sticky; bottom: 0"
			:class="[isInline ? 'mx-md-n4 px-md-4' : null]"
		>
			<ul
				v-if="wizardStore.selectedProperties.length"
				class="list-unstyled hide-scrollbar d-flex gap-2 mb-3 mx-n4 px-4"
				style="overflow-x: scroll"
				data-scroll-lock-scrollable
			>
				<theme-action-badge
					v-for="badge in wizardStore.selectedProperties"
					:key="`xbadge-prop-${badge.id}`"
					tag="li"
					role="button"
					@click:icon="wizardStore.deleteProp(badge.id || badge.value, badge.handleAs)"
				>
					{{ badge.displayValue }}
				</theme-action-badge>
			</ul>

			<div class="d-flex">
				<Link
					class="btn btn-theme-500 w-100 fw-bold"
					:href="wizardResultsLink"
					as="button"
					:disabled="!wizardStore.resultCount"
				>
					{{ !wizardStore.resultCount ? $t('No match, try again') : $t('Show :number restaurants', { number: '' }) }}
				</Link>

				<!-- <i
					v-else
					class="text-muted"
				>
					{{ $t('No results found.') }}
				</i> -->

				<button
					v-if="wizardStore.activeTab !== 2"
					class="btn btn-outline-neutral-100 text-body border-neutral-900 fw-bold ms-2 ms-md-3"
					@click="wizardStore.activeTab++"
				>
					{{ $t('Next') }}
				</button>
			</div>
		</div>
	</div>
</template>
